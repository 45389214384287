import React from "react"
import { Card, CardContent } from "@/components/ui/card"
import CheckInImages from "components/check-in/CheckInImages"
import { useTranslation } from "react-i18next"
import { useThemeStore } from "stores/useThemeStore"

interface SimpleCheckInItemProps {
    checkIn: PublicCheckInDto
    onImageClick: (url: string) => void
}

const PublicSimpleCheckInItem: React.FC<SimpleCheckInItemProps> = ({
    checkIn,
    onImageClick,
}) => {
    const { t } = useTranslation()
    const { theme } = useThemeStore()

    const bgColor = theme === "dark" ? "bg-gray-900" : "bg-gray-200"
    const cardTextColor = theme === "dark" ? "text-white" : "text-black"

    return (
        <Card className={`relative shadow-md rounded-lg p-6 ${bgColor}`}>
            <CardContent className="p-0">
                <div className="flex justify-between items-center mb-4">
                    <h3 className={`text-xl font-semibold ${cardTextColor}`}>
                        {new Date(checkIn.checkInDate).toLocaleDateString()}
                    </h3>
                    {checkIn.weight && (
                        <p className={`${cardTextColor}`}>
                            {t("weight")}: {checkIn.weight} kg
                        </p>
                    )}
                </div>

                <CheckInImages
                    frontPictureUrl={checkIn.frontPictureUrl}
                    sidePictureUrl={checkIn.sidePictureUrl}
                    backPictureUrl={checkIn.backPictureUrl}
                    onImageClick={onImageClick}
                />
            </CardContent>
        </Card>
    )
}

export default PublicSimpleCheckInItem
