import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Loader2 } from "lucide-react"
import { subscribeToNewsletter } from "services/newsletterService"

const LandingPage: React.FC = () => {
    const { t } = useTranslation()

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const handleNewsletterSignup = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        try {
            await subscribeToNewsletter(email)
            setSuccess(true)
            setEmail("")
        } catch (error: any) {
            setError(error.message || t("signup_error"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between bg-gray-100 dark:bg-gray-900 text-black dark:text-white">
            <div className="flex-grow p-6 max-w-4xl mx-auto flex flex-col items-center">
                {/* Heading and Description */}
                <h1 className="text-4xl font-extrabold mb-8 text-black dark:text-white text-center">
                    Strengthara: {t("coming_soon_title")}
                </h1>

                {/* Call to Action Buttons */}
                <div className="flex justify-center space-x-4 mb-12">
                    <Button size="lg" asChild>
                        <Link to="/login">{t("login")}</Link>
                    </Button>
                    <Button size="lg" variant="outline" asChild>
                        <Link to="/register">{t("register")}</Link>
                    </Button>
                </div>

                {/* Features Section */}
                <div className="w-full">
                    <h2 className="text-2xl font-bold text-black dark:text-white mb-6 text-center">
                        {t("features_title")}
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-2 text-black dark:text-white">
                                {t("feature_3_title")}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-400">
                                {t("feature_3_description")}
                            </p>
                        </div>
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-2 text-black dark:text-white">
                                {t("feature_4_title")}
                            </h3>
                            <p className="text-gray-600 dark:text-gray-400">
                                {t("feature_4_description")}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Newsletter Sign-Up */}
                <div className="mt-12 w-full">
                    <h2 className="text-xl font-bold text-black dark:text-white mb-4 text-center">
                        {t("newsletter_title")}
                    </h2>
                    <p className="text-gray-600 dark:text-gray-400 mb-6 text-center">
                        {t("newsletter_description", {
                            app_name: "Strengthara",
                        })}
                    </p>
                    <form
                        onSubmit={handleNewsletterSignup}
                        className="space-y-4 flex flex-col items-center"
                    >
                        <div className="flex space-x-2">
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t("email_placeholder")}
                                className="w-64 bg-white dark:bg-gray-800 text-black dark:text-white"
                            />
                            <Button type="submit" disabled={loading}>
                                {loading ? (
                                    <Loader2 className="h-5 w-5 animate-spin" />
                                ) : (
                                    t("subscribe")
                                )}
                            </Button>
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                        {success && (
                            <p className="text-green-500">
                                {t("signup_success")}
                            </p>
                        )}
                    </form>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-gray-200 dark:bg-gray-800 p-4 text-sm text-center text-gray-600 dark:text-gray-400">
                <p>{t("footer_text", { app_name: "Strengthara" })}</p>
            </footer>
        </div>
    )
}

export default LandingPage
