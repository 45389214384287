import React, { useState, useEffect, useRef } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import { useAuthStore } from "stores/useAuthStore"
import { useTranslation } from "react-i18next"
import { useLanguageStore } from "stores/useLanguageStore"
import { useThemeStore } from "stores/useThemeStore"

// ShadCN components
import { Button } from "components/ui/button"
import { Select, SelectContent, SelectItem } from "components/ui/select"
import { Navbar } from "components/ui/navbar"
import { Container } from "components/ui/container"

const MainLayout: React.FC = () => {
    const { user, logout } = useAuthStore()
    const { i18n, t } = useTranslation()
    const { language, setLanguage } = useLanguageStore()
    const { theme } = useThemeStore()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const location = useLocation()
    const menuRef = useRef<HTMLDivElement>(null) // Ref for the menu

    // Sync Zustand language with i18next
    useEffect(() => {
        if (language !== i18n.language) {
            i18n.changeLanguage(language)
        }
    }, [language, i18n])

    // Close the menu when navigating to a new page
    useEffect(() => {
        setIsMenuOpen(false)
    }, [location])

    // Handle closing the menu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false)
            }
        }

        if (isMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isMenuOpen])

    const changeLanguage = (value: string) => {
        setLanguage(value)
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark")
        } else {
            document.documentElement.classList.remove("dark")
        }
    }, [theme])

    return (
        <div className={`flex flex-col h-screen font-sans ${theme}`}>
            {/* Top Navbar */}
            <Navbar className="p-4 bg-white dark:bg-black text-black dark:text-white shadow-md">
                <div className="flex justify-between items-center w-full">
                    <Link
                        to={user ? "/dashboard" : "/"}
                        className="text-xl font-bold text-black dark:text-white"
                    >
                        Strengthara
                    </Link>
                    <Button
                        onClick={toggleMenu}
                        className="sm:hidden text-black dark:text-white bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700"
                    >
                        &#9776;
                    </Button>
                </div>
            </Navbar>

            {/* Side Menu + Content Layout */}
            <div className="flex flex-grow overflow-hidden">
                {/* Side Menu */}
                <aside
                    ref={menuRef}
                    className={`w-64 p-6 ${
                        isMenuOpen ? "block" : "hidden"
                    } sm:block bg-white dark:bg-black text-black dark:text-white border-r border-gray-200 dark:border-gray-800 space-y-6 absolute sm:relative top-0 left-0 h-full z-50 sm:z-auto sm:top-auto sm:left-auto`}
                >
                    <nav className="space-y-2">
                        <Button
                            asChild
                            variant="ghost"
                            className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                        >
                            <Link to="/dashboard">{t("dashboard")}</Link>
                        </Button>
                        <Button
                            asChild
                            variant="ghost"
                            className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                        >
                            <Link to="/checkin">{t("check_in")}</Link>
                        </Button>
                        <Button
                            asChild
                            variant="ghost"
                            className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                        >
                            <Link to="/progress-views">
                                {t("progress_view_generator")}
                            </Link>
                        </Button>
                        <Button
                            asChild
                            variant="ghost"
                            className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                        >
                            <Link to="/settings">{t("settings")}</Link>
                        </Button>
                        {/* User Links */}
                        {user ? (
                            <>
                                <Button
                                    asChild
                                    variant="ghost"
                                    className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                                >
                                    <Link to="/profile">{t("profile")}</Link>
                                </Button>
                                <Button
                                    variant="ghost"
                                    onClick={logout}
                                    className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                                >
                                    {t("logout")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    asChild
                                    variant="ghost"
                                    className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                                >
                                    <Link to="/login">{t("login")}</Link>
                                </Button>
                                <Button
                                    asChild
                                    variant="ghost"
                                    className="justify-start w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
                                >
                                    <Link to="/register">{t("register")}</Link>
                                </Button>
                            </>
                        )}

                        {/* Language Switcher */}
                        <Select value={language} onValueChange={changeLanguage}>
                            <SelectContent>
                                <SelectItem value="en">English</SelectItem>
                                <SelectItem value="sv">Swedish</SelectItem>
                            </SelectContent>
                        </Select>
                    </nav>
                </aside>

                {/* Main Content */}
                <main className="flex-grow p-6 overflow-auto bg-white dark:bg-black text-black dark:text-white">
                    <Container className="flex flex-col items-start justify-start w-full">
                        <Outlet />
                    </Container>
                </main>
            </div>
        </div>
    )
}

export default MainLayout
