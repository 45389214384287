import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useProgressViewStore } from "stores/useProgressViewStore"
import ImageModal from "components/shared/ImageModal"
import PublicSimpleCheckInItem from "components/progress-view/PublicSimpleCheckInItem"

const ProgressView: React.FC = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const { publicProgressView, error, fetchPublicProgressView } =
        useProgressViewStore()
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            fetchPublicProgressView(id)
        }
    }, [id, fetchPublicProgressView])

    const handleImageClick = (url: string) => {
        setSelectedImage(url)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedImage(null)
    }

    if (error) {
        return (
            <div className="text-center text-lg text-red-500 min-h-screen flex items-center justify-center">
                {t("progress_not_found")}
            </div>
        )
    }

    if (!publicProgressView) {
        return (
            <div className="text-center text-lg min-h-screen flex items-center justify-center">
                {t("loading")}...
            </div>
        )
    }

    const { checkIns } = publicProgressView

    return (
        <div className="min-h-screen flex flex-col justify-between bg-gray-900 text-white">
            <div className="flex-grow p-4 sm:p-6 max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold text-center mb-8">
                    {t("progress_view_title")}
                </h1>

                {/* Display check-ins */}
                <div className="space-y-6">
                    {checkIns.map((checkIn) => (
                        <PublicSimpleCheckInItem
                            key={checkIn.id}
                            checkIn={checkIn}
                            onImageClick={handleImageClick}
                        />
                    ))}
                </div>

                {/* Image Modal for viewing images */}
                <ImageModal
                    selectedImage={selectedImage}
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                />
            </div>

            {/* Footer */}
            <footer className="text-center p-4 bg-gray-800 text-gray-400">
                <p className="text-sm">{t("generated_by_strengthara")}</p>
                <Link
                    to="/"
                    className="text-blue-500 hover:text-blue-700 text-sm underline"
                >
                    {t("visit_strengthara")}
                </Link>
                <p>{t("footer_text", { app_name: "Strengthara" })}</p>
            </footer>
        </div>
    )
}

export default ProgressView
