import React from "react"
import { useTranslation } from "react-i18next"

interface CheckInImagesProps {
    frontPictureUrl: string | null
    sidePictureUrl: string | null
    backPictureUrl: string | null
    onImageClick: (url: string) => void
}

const CheckInImages: React.FC<CheckInImagesProps> = ({
    frontPictureUrl,
    sidePictureUrl,
    backPictureUrl,
    onImageClick,
}) => {
    const { t } = useTranslation()

    return (
        <div className="pt-4 pb-4">
            <div className="flex flex-wrap justify-center gap-4">
                {frontPictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() => onImageClick(frontPictureUrl)}
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={frontPictureUrl}
                            alt={t("front_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("front")}</p>
                        </div>
                    </div>
                )}
                {sidePictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() => onImageClick(sidePictureUrl)}
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={sidePictureUrl}
                            alt={t("side_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("side")}</p>
                        </div>
                    </div>
                )}
                {backPictureUrl && (
                    <div
                        className="relative group cursor-pointer"
                        onClick={() => onImageClick(backPictureUrl)}
                    >
                        <img
                            className="h-36 w-36 sm:h-40 sm:w-40 object-cover rounded-lg shadow-lg hover:scale-105 transform transition-all duration-300"
                            src={backPictureUrl}
                            alt={t("back_picture")}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <p className="text-white font-bold">{t("back")}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CheckInImages
